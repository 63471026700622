export default {
  item: {
    data: null,
    loading: false,
    error: null
  },
  create: {
    loading: false,
    error: null
  },
  update: {
    loading: false,
    error: null
  },
  patch: {
    item: [],
    loading: false,
    loadingKeys: [],
    error: null
  },
  patch_product: {
    item: [],
    loading: false,
    error: null
  },
  remove: {
    loading: false,
    error: null
  },
  report: {
    data: null,
    loading: false,
    error: null
  },
  reportHistory: {
    data: null,
    loading: false,
    error: null
  },
  products: {
    selectedProducts: [],
    loadingAddBulk: false,
    rejectedProducts: null
  }
} as RetailMediaIndustryCampaignState
